import { styled } from '@mui/system'
import BHLink from '../../molecules/link'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

const Root = styled(MuiBreadcrumbs)`
	margin-top: calc(var(--spacing) * 1.25);
	margin-bottom: calc(var(--spacing) * 1.25);
	font-size: var(--font-size-body1);

	padding: var(--spacing-3) calc(var(--spacing) * 2.5);
	margin: 0;
	display: flex;
	justify-content: center;
	border-top: 1px solid var(--color-grey100);
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: var(--spacing-3) var(--spacing-1);
	}
`

const LinkCurrent = styled(BHLink)`
	font-size: var(--font-size-body1);
`

const LinkPrev = styled(BHLink)`
	font-size: var(--font-size-body1);
`

export { LinkCurrent, LinkPrev, Root }
